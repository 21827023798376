import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  mobileOrderButton,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="90VH"
          w="100%"
          image={`url(${mobileHero}) 12% / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
        >
          <Header />
          <CFView textCenter column center w="100%" bg="rgb(0,0,0,.85)">
            <CFView pt="15px" pb="10px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="690px"
          w="100%"
          maxWidth="1400px"
          image={`url(${hero}) top / cover no-repeat`}
          zIndex={90}
          column
          center
          justifyBetween
        >
          <Header />
          <CFView
            row
            center
            w="100%"
            maxWidth="1100px"
            bg="rgb(0,0,0,.85)"
            pt="8px"
          >
            <CFView
              color="white"
              style={{ fontSize: 32 }}
              raleway
              xBold
              pb="7px"
            >
              Sushi Yama Broadway Location
            </CFView>
            <CFView pulsate ml="20px" pb="3px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
