import React from 'react'
import { CFImage, CFLink, CFView } from 'components'
import { cflogo } from 'images'

export default () => {
  return (
    <CFView column center h="80px" bg="black">
      <CFView h6 color="white" content>
        COPYRIGHT © 2022 CODEFUSION INC.
      </CFView>
      <CFLink href="https://codefusion.tech">
        <CFImage h="30px" pv="5px" src={cflogo} alt="CodeFusion" />
      </CFLink>
    </CFView>
  )
}
